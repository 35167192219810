import React from "react";
import { Container, Flex, Heading, Link, Text } from "@chakra-ui/react";
import Layout from "../components/Layout";
import { Wave } from "../components/Wave";
import { Link as GatsbyLink } from "gatsby";

export default function NotFoundPage() {
  return (
    <Layout>
      <Flex
        alignItems="center"
        bg="golden"
        bgImg="/images/amarelo-back.svg"
        bgSize="cover"
        h="100vh"
        justifyContent="center"
        pos="relative"
      >
        <Container>
          <Heading color="black" fontSize={["12vw", "5rem"]}>
            Opa!
          </Heading>
          <Text color="black" textAlign="center" fontSize="xl">
            Infelizmente, o conteúdo não foi encontrado! Clique{" "}
            <Link
              _hover={{ color: "black" }}
              as={GatsbyLink}
              color="brown"
              fontWeight={900}
              textDecor="none"
              to="/"
            >
              aqui
            </Link>{" "}
            para voltar para a página inicial, ou utilize nossa busca para achar
            algum conteúdo que seja de seu interesse.
          </Text>
        </Container>
        <Wave color="#fff8db" />
      </Flex>
    </Layout>
  );
}
